import styled from "styled-components"
import media from "styled-media-query"
import Img from "gatsby-image"

export const Empresa = styled.section`
  padding-bottom: 10rem;
  padding-top: 1rem;

  ${media.lessThan(`large`)`
    padding-bottom: 2rem;
  `}
`

export const EmpresaWrapper = styled.div`
  width: 80vw;
  min-width: 960px;
  margin: 0 auto;
  display: flex;

  ${media.lessThan("large")`
    width: 100%;
    min-width: 100%;
    flex-direction: column;
  `}
`

export const EmpresaHeader = styled.div`
  display: block;
  width: 100%;
  margin: 4rem auto 0;
  padding: 1.5rem 2rem 1.5rem 0;
`

export const EmpresaHeaderTitle = styled.h1`
  font-size: 2rem;
  text-align: left;
  font-weight: 700;
  margin-bottom: 2rem;
  text-transform: uppercase;
`

export const EmpresaHr = styled.hr`
  width: 3rem;
  height: 1px;
  background: var(--black);
  text-align: left;
  margin: 0;
`

export const EmpresaText = styled.p`
  font-family: "Lato", sans-serif;
  line-height: 2;
  color: var(--text);
  margin-bottom: 1rem;
`

export const EmpresaBox = styled.div`
  width: 50%;
  display: block;
  justify-content: flex-start;

  ${media.lessThan("large")`
    width: 100%;
    padding: 1rem;
  `}
`

export const EmpresaLista = styled.ul`
  display: block;
  margin: 0 3rem 0 0;
`

export const EmpresaListaItem = styled.li`
  font-family: "Lato", sans-serif;
  margin: 0.75rem 0;
  list-style: disc inside;
  color: var(--text);
`

export const EmpresaImg = styled(Img)`
  margin: 6rem auto;
  width: 90%;
  height: auto;
  display: block;

  ${media.lessThan("large")`
    width: 100%;
    padding: 1rem;
    margin-top: 2rem;
  `}
`
