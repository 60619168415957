import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Empresa from "../components/Empresa"
import CallToAction from "../components/CallToAction"
import Contato from "../components/Contato"

const EmpresaPage = () => {
  return (
    <Layout>
      <SEO title="Empresa" />
      <Empresa />
      <CallToAction />
      <Contato title={false} />
    </Layout>
  )
}

export default EmpresaPage
