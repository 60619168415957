import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as S from "./styled"

const Empresa = () => {
  const { empresaImg } = useStaticQuery(graphql`
    query {
      empresaImg: file(relativePath: { eq: "geral/empresa.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <S.Empresa>
      <S.EmpresaWrapper>
        <S.EmpresaBox>
          <S.EmpresaHeader>
            <S.EmpresaHeaderTitle>Nossa Empresa</S.EmpresaHeaderTitle>
            <S.EmpresaHr />
          </S.EmpresaHeader>
          <S.EmpresaText>
            A Açocon conta com estrutura e equipe especializada para atender e
            executar serviços de Corte , Dobra e Montagem de Aço para construção
            civil, voltado para produção de armação de colunas, vigas, sapatas,
            estribos na medida exata do projeto e sob a orientação e
            acompanhamento técnico desde o cálculo estrutural à concretagem das
            peças, conforme o projeto de armação das obras, com entrega conforme
            cronograma especificado pelo cliente. Entre os serviços de alto
            valor agregado e orientados para agilidade e eficiência de pequenas,
            médias e grandes obras.
          </S.EmpresaText>
          <S.EmpresaText>
            O comprometimento com os prazos de entrega, a qualidade dos seus
            serviços, e uma política de preços altamente competitiva, fazem com
            que a Açocon seja a garantia de uma parceria bem estruturada para o
            seu projeto.
          </S.EmpresaText>
          <S.EmpresaText>
            Prova disso é o reconhecimento do mercado e a satisfação de nossos
            clientes.
          </S.EmpresaText>
          <S.EmpresaText>Vantagens dos nossos serviços:</S.EmpresaText>
          <S.EmpresaLista>
            <S.EmpresaListaItem>Redução de custos</S.EmpresaListaItem>
            <S.EmpresaListaItem>
              Dispensa o uso de bancadas de armação
            </S.EmpresaListaItem>
            <S.EmpresaListaItem>
              Racionalização da mão-de-obra
            </S.EmpresaListaItem>
            <S.EmpresaListaItem>
              Redução das perdas por sobras de pontas e extravios
            </S.EmpresaListaItem>
            <S.EmpresaListaItem>
              Entrega conforme o cronograma da obra
            </S.EmpresaListaItem>
            <S.EmpresaListaItem>
              Precisão no corte e na dobra
            </S.EmpresaListaItem>
            <S.EmpresaListaItem>
              Material etiquetado conforme as especificações do projeto
            </S.EmpresaListaItem>
            <S.EmpresaListaItem>
              Maior espaço no canteiro de obras
            </S.EmpresaListaItem>
          </S.EmpresaLista>
          <S.EmpresaText>Atendemos Araras e Região.</S.EmpresaText>
        </S.EmpresaBox>
        <S.EmpresaBox>
          <S.EmpresaImg fluid={empresaImg.childImageSharp.fluid} />
        </S.EmpresaBox>
      </S.EmpresaWrapper>
    </S.Empresa>
  )
}

export default Empresa
